import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchPermissionsByUserId } from '../services/api/weDeliveryPermissionsApi';

const initialState = {
  token: null,
  user: null,
  role: null,
  permissions: {},
};

// Thunk para cargar los permisos del usuario
export const loadPermissions = createAsyncThunk(
  'auth/loadPermissions',
  async (userId) => {
    const response = await fetchPermissionsByUserId(userId);
    return response;
  }
);

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setCredentials: (state, action) => {
      const { token, user, rememberMe } = action.payload;
      state.token = token;
      state.user = user;
      state.role = user.roleId;
      // No establecemos permissions aquí porque se actualiza en loadPermissions
    
      // Guardar token y usuario en localStorage o sessionStorage
      const storage = rememberMe ? localStorage : sessionStorage;
      storage.setItem('jwtToken', token);
      storage.setItem('user', JSON.stringify(user));
    },
    
    logOut: (state) => {
      state.token = null;
      state.user = null;
      state.role = null;
      state.permissions = null;

      // Limpiar almacenamiento
      localStorage.removeItem('jwtToken');
      sessionStorage.removeItem('jwtToken');
      localStorage.removeItem('user');
      sessionStorage.removeItem('user');
      localStorage.removeItem('permissions');
      sessionStorage.removeItem('permissions');
    },
    loadFromStorage: (state) => {
      const token = localStorage.getItem('jwtToken') || sessionStorage.getItem('jwtToken');
      const user = JSON.parse(localStorage.getItem('user')) || JSON.parse(sessionStorage.getItem('user'));
      const permissions = JSON.parse(localStorage.getItem('permissions')) || JSON.parse(sessionStorage.getItem('permissions'));
    
      if (token && user) {
        state.token = token;
        state.user = user;
        state.role = user.roleId;
        state.permissions = permissions || {}; // Asegurar que permissions es un objeto
      }
    },
    
  },
  extraReducers: (builder) => {
    builder.addCase(loadPermissions.fulfilled, (state, action) => {
      state.permissions = action.payload;
      // Actualizar el almacenamiento con los permisos obtenidos
      localStorage.setItem('permissions', JSON.stringify(action.payload));
      sessionStorage.setItem('permissions', JSON.stringify(action.payload));
    });
  },  
});

export const { setCredentials, logOut, loadFromStorage } = authSlice.actions;
export default authSlice.reducer;
