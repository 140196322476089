import React, { useState } from 'react';
import {
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Collapse,
} from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import AssignmentIcon from '@mui/icons-material/Assignment';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import LogoutIcon from '@mui/icons-material/Logout';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import BuildIcon from '@mui/icons-material/Build';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import WorkIcon from '@mui/icons-material/Work';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import PendingIcon from '@mui/icons-material/Pending';
import HistoryIcon from '@mui/icons-material/History';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import BarChartIcon from '@mui/icons-material/BarChart';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import PeopleIcon from '@mui/icons-material/People';
import PolicyIcon from '@mui/icons-material/Policy';
import PolicyIconOn from '@mui/icons-material/PolicyOutlined';
import PolicyIconOff from '@mui/icons-material/PolicyTwoTone';
import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary';
import '../../styles/components.scss';
import { useDispatch } from 'react-redux';
import { logOut } from '../../redux/authSlice';
import SidebarWelcome from '../SidebarWelcome';
import DownloadIcon from '@mui/icons-material/Download';

const SidebarAdmin = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(logOut());
    navigate('/');
  };

  const handleDownloadApp = () => {
    window.open('https://mega.nz/file/uQBBjAzY#JSNytewcR4kYFFUWQMwpu2hcBVuFjKlABm8yP9HPAvU', '_blank'); // Reemplaza con tu enlace de Mega
  };

  // Estados para manejar la expansión de los submenús
  const [openUsers, setOpenUsers] = useState(false);
  const [openServices, setOpenServices] = useState(false);
  const [openPolicies, setOpenPolicies] = useState(false);
  const [openRates, setOpenRates] = useState(false); // Estado para el submenú de tarifas

  const handleClickUsers = () => {
    setOpenUsers(!openUsers);
  };

  const handleClickServices = () => {
    setOpenServices(!openServices);
  };

  const handleClickPolicies = () => {
    setOpenPolicies(!openPolicies);
  };

  const handleClickRates = () => {
    setOpenRates(!openRates); // Alternar la expansión del submenú de tarifas
  };

  return (
    <div className="sidebar">
      {/* Sección de bienvenida */}
      <SidebarWelcome />

      <List>
        <ListItem button onClick={handleClickUsers}>
          <ListItemIcon>
            <PermContactCalendarIcon />
          </ListItemIcon>
          <ListItemText primary="Usuarios" />
          {openUsers ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={openUsers} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem button onClick={() => navigate('/admin/users')} sx={{ pl: 4 }}>
              <ListItemIcon><PermContactCalendarIcon /></ListItemIcon>
              <ListItemText primary="Gestión de Usuarios" />
            </ListItem>
            <ListItem button onClick={() => navigate('/admin/users-pending')} sx={{ pl: 4 }}>
              <ListItemIcon><PersonAddIcon /></ListItemIcon>
              <ListItemText primary="Usuarios Pendientes" />
            </ListItem>
            <ListItem button onClick={() => navigate('/admin/inactive-users')} sx={{ pl: 4 }}>
              <ListItemIcon><PeopleIcon /></ListItemIcon>
              <ListItemText primary="Usuarios Inactivos" />
            </ListItem>
            <ListItem button onClick={() => navigate('/admin/permission-manager')} sx={{ pl: 4 }}>
              <ListItemIcon><BuildIcon /></ListItemIcon>
              <ListItemText primary="Gestión de Permisos" />
            </ListItem>
            <ListItem button onClick={() => navigate('/admin/profile')} sx={{ pl: 4 }}>
              <ListItemIcon><AccountCircleIcon /></ListItemIcon>
              <ListItemText primary="Perfil" />
            </ListItem>
          </List>
        </Collapse>

        <ListItem button onClick={handleClickServices}>
          <ListItemIcon>
            <AssignmentIcon />
          </ListItemIcon>
          <ListItemText primary="Servicios" />
          {openServices ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={openServices} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem button onClick={() => navigate('/admin/services')} sx={{ pl: 4 }}>
              <ListItemIcon><AssignmentIcon /></ListItemIcon>
              <ListItemText primary="Servicios" />
            </ListItem>
            {/* Nueva entrada de menú para Localización de Conductores */}
            <ListItem button onClick={() => navigate('/admin/driver-location')} sx={{ pl: 4 }}>
              <ListItemIcon><LocationOnIcon /></ListItemIcon>
              <ListItemText primary="Localización de Conductores" />
            </ListItem>
            {/* Nueva entrada de menú para Conductores de Servicios */}
            <ListItem button onClick={() => navigate('/admin/driver-services')} sx={{ pl: 4 }}>
              <ListItemIcon><WorkIcon /></ListItemIcon>
              <ListItemText primary="Conductores de Servicios" />
            </ListItem>
            {/* Nueva entrada para Vehículos de Intercambio */}
            <ListItem button onClick={() => navigate('/admin/trades')} sx={{ pl: 4 }}>
              <ListItemIcon><WorkIcon /></ListItemIcon>
              <ListItemText primary="Vehículos de Intercambio" />
            </ListItem>
            {/* Otras entradas de menú existentes */}
            <ListItem button onClick={() => navigate('/admin/edit-services')} sx={{ pl: 4 }}>
              <ListItemIcon><AddCircleIcon /></ListItemIcon>
              <ListItemText primary="Editar Servicios" />
            </ListItem>
            <ListItem button onClick={() => navigate('/admin/create-services')} sx={{ pl: 4 }}>
              <ListItemIcon><AddCircleIcon /></ListItemIcon>
              <ListItemText primary="Crear Servicios" />
            </ListItem>
            <ListItem button onClick={() => navigate('/admin/services-pending')} sx={{ pl: 4 }}>
              <ListItemIcon><PendingIcon /></ListItemIcon>
              <ListItemText primary="Servicios Pendientes" />
            </ListItem>
            <ListItem button onClick={() => navigate('/admin/services-assigned')} sx={{ pl: 4 }}>
              <ListItemIcon><AssignmentIcon /></ListItemIcon>
              <ListItemText primary="Servicios Asignados" />
            </ListItem>
            <ListItem button onClick={() => navigate('/admin/services-in-progress')} sx={{ pl: 4 }}>
              <ListItemIcon><HistoryIcon /></ListItemIcon>
              <ListItemText primary="Servicios en Progreso" />
            </ListItem>
            <ListItem button onClick={() => navigate('/admin/services-pending-review')} sx={{ pl: 4 }}>
              <ListItemIcon><EventAvailableIcon /></ListItemIcon>
              <ListItemText primary="Servicios Pendientes de Revisión" />
            </ListItem>
            <ListItem button onClick={() => navigate('/admin/services-completed')} sx={{ pl: 4 }}>
              <ListItemIcon><DoneAllIcon /></ListItemIcon>
              <ListItemText primary="Servicios Completados" />
            </ListItem>
            <ListItem button onClick={() => navigate('/admin/service-status')} sx={{ pl: 4 }}>
              <ListItemIcon><DoneAllIcon /></ListItemIcon>
              <ListItemText primary="Manejo de Estados de Servicios" />
            </ListItem>
            <ListItem button onClick={() => navigate('/admin/services-images')} sx={{ pl: 4 }}>
              <ListItemIcon><PhotoLibraryIcon /></ListItemIcon>
              <ListItemText primary="Imágenes de Servicios" />
            </ListItem>
          </List>
        </Collapse>

        <ListItem button onClick={handleClickPolicies}>
          <ListItemIcon>
            <PolicyIcon />
          </ListItemIcon>
          <ListItemText primary="Políticas" />
          {openPolicies ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={openPolicies} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem button onClick={() => navigate('/admin/cookie-policy')} sx={{ pl: 4 }}>
              <ListItemIcon><PolicyIcon /></ListItemIcon>
              <ListItemText primary="Política de Cookies" />
            </ListItem>
            <ListItem button onClick={() => navigate('/admin/legal-notice')} sx={{ pl: 4 }}>
              <ListItemIcon><PolicyIconOn /></ListItemIcon>
              <ListItemText primary="Aviso Legal" />
            </ListItem>
            <ListItem button onClick={() => navigate('/admin/privacy-policy')} sx={{ pl: 4 }}>
              <ListItemIcon><PolicyIconOff /></ListItemIcon>
              <ListItemText primary="Política de Privacidad" />
            </ListItem>
          </List>
        </Collapse>

        {/* Submenú para Tarifas */}
        <ListItem button onClick={handleClickRates}>
          <ListItemIcon>
            <AttachMoneyIcon />
          </ListItemIcon>
          <ListItemText primary="Tarifas" />
          {openRates ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={openRates} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem button onClick={() => navigate('/admin/rates')} sx={{ pl: 4 }}>
              <ListItemIcon><AttachMoneyIcon /></ListItemIcon>
              <ListItemText primary="Gestión de Tarifas" />
            </ListItem>
            <ListItem button onClick={() => navigate('/admin/rate-segments')} sx={{ pl: 4 }}>
              <ListItemIcon><BarChartIcon /></ListItemIcon>
              <ListItemText primary="Segmentos de Tarifas" />
            </ListItem>
            <ListItem button onClick={() => navigate('/admin/rate-entities')} sx={{ pl: 4 }}>
              <ListItemIcon><WorkIcon /></ListItemIcon>
              <ListItemText primary="Entidades de Tarifas" />
            </ListItem>
          </List>
        </Collapse>

        <ListItem button onClick={() => navigate('/admin/reports')}>
          <ListItemIcon><PlaylistAddCheckIcon /></ListItemIcon>
          <ListItemText primary="Informes" />
        </ListItem>
        <ListItem button onClick={() => navigate('/admin/extra-hours')}>
          <ListItemIcon><BuildIcon /></ListItemIcon>
          <ListItemText primary="Horas Extra" />
        </ListItem>
        <ListItem button onClick={() => navigate('/admin/expenses')}>
          <ListItemIcon><AttachMoneyIcon /></ListItemIcon>
          <ListItemText primary="Gastos" />
        </ListItem>
        <ListItem button onClick={handleDownloadApp}>
          <ListItemIcon><DownloadIcon /></ListItemIcon>
          <ListItemText primary="Descargar App" />
        </ListItem>
        <ListItem button onClick={handleLogout}>
          <ListItemIcon><LogoutIcon /></ListItemIcon>
          <ListItemText primary="Cerrar sesión" />
        </ListItem>
      </List>
    </div>
  );
};

export default SidebarAdmin;
