import api from './apiConfig';

/**
 * Obtener todos los permisos.
 * @returns {Promise<Array>} - La respuesta del servidor con la lista de todos los permisos.
 */
export const fetchAllPermissions = async () => {
    try {
        const response = await api.get('/wedelivery-permissions');
        return response.data;
    } catch (error) {
        console.error('Error al obtener todos los permisos:', error);
        throw error;
    }
};

/**
 * Obtener permisos por ID de usuario.
 * @param {Number|String} userId - ID del usuario.
 * @returns {Promise<Object>} - La respuesta del servidor con los permisos del usuario.
 */
export const fetchPermissionsByUserId = async (userId) => {
    try {
      const response = await api.get(`/wedelivery-permissions/user/${userId}`);
      console.log('Permisos obtenidos:', response.data);
      return response.data;
    } catch (error) {
      console.error(`Error al obtener permisos para el ID de usuario ${userId}:`, error);
      throw error;
    }
  };
  

/**
 * Guardar nuevos permisos.
 * @param {Object} permissionsData - Datos de los permisos a guardar.
 * @returns {Promise<Object>} - La respuesta del servidor con los detalles de los permisos guardados.
 */
export const savePermissions = async (permissionsData) => {
    try {
        const response = await api.post('/wedelivery-permissions', permissionsData);
        return response.data;
    } catch (error) {
        console.error('Error al guardar los permisos:', error);
        throw error;
    }
};

/**
 * Actualizar permisos por ID.
 * @param {Number|String} id - ID de los permisos a actualizar.
 * @param {Object} permissionsData - Datos de los permisos actualizados.
 * @returns {Promise<Object>} - La respuesta del servidor con los detalles de los permisos actualizados.
 */
export const updatePermissions = async (id, permissionsData) => {
    try {
        const response = await api.put(`/wedelivery-permissions/${id}`, permissionsData);
        return response.data;
    } catch (error) {
        console.error(`Error al actualizar los permisos con ID ${id}:`, error);
        throw error;
    }
};

/**
 * Eliminar permisos por ID.
 * @param {Number|String} id - ID de los permisos a eliminar.
 * @returns {Promise<void>} - La respuesta del servidor confirmando la eliminación de los permisos.
 */
export const deletePermissionsById = async (id) => {
    try {
        await api.delete(`/wedelivery-permissions/${id}`);
    } catch (error) {
        console.error(`Error al eliminar los permisos con ID ${id}:`, error);
        throw error;
    }
};
