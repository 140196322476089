import React, { useEffect, useState } from 'react';
import { fetchUsersByRoleId } from '../services/api/userApi';
import { fetchAllPermissions, updatePermissions } from '../services/api/weDeliveryPermissionsApi';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Switch, Button, Typography, Box } from '@mui/material';
import '../styles/pages.scss';
import '../styles/global.scss';
import '../styles/components.scss';

const PermissionManager = () => {
  const [users, setUsers] = useState([]);
  const [permissions, setPermissions] = useState([]);

  useEffect(() => {
    const fetchUsersAndPermissions = async () => {
      try {
        const usersResponse = await fetchUsersByRoleId(5); // Obtener usuarios del rol oficina_wedelivery
        setUsers(usersResponse);

        const permissionsResponse = await fetchAllPermissions(); // Obtener permisos de usuarios
        setPermissions(permissionsResponse);
      } catch (error) {
        console.error('Error al obtener usuarios o permisos:', error);
      }
    };

    fetchUsersAndPermissions();
  }, []);

  const getPermissionsForUser = (userId) => {
    return permissions.find((permission) => permission.userId === userId) || {};
  };

  const handlePermissionChange = (userId, field) => {
    setPermissions((prevPermissions) =>
      prevPermissions.map((permission) =>
        permission.userId === userId
          ? { ...permission, [field]: !permission[field] }
          : permission
      )
    );
  };

  const handleSave = async () => {
    try {
      await Promise.all(
        permissions.map((permission) =>
          updatePermissions(permission.id, permission)
        )
      );
      alert('Permisos actualizados con éxito');
    } catch (error) {
      console.error('Error al actualizar permisos:', error);
      alert('Error al actualizar permisos');
    }
  };

  return (
    <div className="permission-manager">
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
        <Typography variant="h4" className="main-title">Gestión de Permisos</Typography>
        <Button variant="contained" color="primary" onClick={handleSave}>
          Guardar Cambios
        </Button>
      </Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead className="table-header">
            <TableRow>
              <TableCell>Nombre</TableCell>
              <TableCell>Apellido</TableCell>
              <TableCell>NIF</TableCell>
              {/* Aquí actualizamos los nombres de los permisos */}
              <TableCell>Estado del Servicio</TableCell>
              <TableCell>Ubicación del Conductor</TableCell>
              <TableCell>Tarifas</TableCell>
              <TableCell>Usuarios</TableCell>
              <TableCell>Informes</TableCell>
              <TableCell>Servicios</TableCell>
              <TableCell>Gastos</TableCell>
              <TableCell>Horas Extra</TableCell>
              <TableCell>Perfil</TableCell>
              {/* Nuevos permisos */}
              <TableCell>Crear Servicios</TableCell>
              <TableCell>Servicios Asignados</TableCell>
              <TableCell>Servicios en Progreso</TableCell>
              <TableCell>Servicios Pendientes</TableCell>
              <TableCell>Servicios Pendientes de Revisión</TableCell>
              <TableCell>Servicios Completados</TableCell>
              <TableCell>Editar Servicios</TableCell>
              <TableCell>Segmentos de Tarifas</TableCell>
              <TableCell>Entidades de Tarifas</TableCell>
              <TableCell>Política de Cookies</TableCell>
              <TableCell>Aviso Legal</TableCell>
              <TableCell>Política de Privacidad</TableCell>
              <TableCell>Imágenes de Servicios</TableCell>
              <TableCell>Conductores de Servicios</TableCell>
              <TableCell>Vehículos de Intercambio</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map((user) => {
              const userPermissions = getPermissionsForUser(user.idUser);
              return (
                <TableRow key={user.idUser}>
                  <TableCell>{user.firstName}</TableCell>
                  <TableCell>{user.lastName}</TableCell>
                  <TableCell>{user.nif}</TableCell>
                  {/* Actualizamos los campos de permisos */}
                  <TableCell>
                    <Switch
                      checked={userPermissions.serviceStatus || false}
                      onChange={() =>
                        handlePermissionChange(user.idUser, 'serviceStatus')
                      }
                    />
                  </TableCell>
                  <TableCell>
                    <Switch
                      checked={userPermissions.driverLocation || false}
                      onChange={() =>
                        handlePermissionChange(user.idUser, 'driverLocation')
                      }
                    />
                  </TableCell>
                  <TableCell>
                    <Switch
                      checked={userPermissions.rates || false}
                      onChange={() =>
                        handlePermissionChange(user.idUser, 'rates')
                      }
                    />
                  </TableCell>
                  <TableCell>
                    <Switch
                      checked={userPermissions.users || false}
                      onChange={() =>
                        handlePermissionChange(user.idUser, 'users')
                      }
                    />
                  </TableCell>
                  <TableCell>
                    <Switch
                      checked={userPermissions.reports || false}
                      onChange={() =>
                        handlePermissionChange(user.idUser, 'reports')
                      }
                    />
                  </TableCell>
                  <TableCell>
                    <Switch
                      checked={userPermissions.services || false}
                      onChange={() =>
                        handlePermissionChange(user.idUser, 'services')
                      }
                    />
                  </TableCell>
                  <TableCell>
                    <Switch
                      checked={userPermissions.expenses || false}
                      onChange={() =>
                        handlePermissionChange(user.idUser, 'expenses')
                      }
                    />
                  </TableCell>
                  <TableCell>
                    <Switch
                      checked={userPermissions.extraHours || false}
                      onChange={() =>
                        handlePermissionChange(user.idUser, 'extraHours')
                      }
                    />
                  </TableCell>
                  <TableCell>
                    <Switch
                      checked={userPermissions.profile || false}
                      onChange={() =>
                        handlePermissionChange(user.idUser, 'profile')
                      }
                    />
                  </TableCell>
                  {/* Nuevos permisos */}
                  <TableCell>
                    <Switch
                      checked={userPermissions.createServices || false}
                      onChange={() =>
                        handlePermissionChange(user.idUser, 'createServices')
                      }
                    />
                  </TableCell>
                  <TableCell>
                    <Switch
                      checked={userPermissions.servicesAssigned || false}
                      onChange={() =>
                        handlePermissionChange(user.idUser, 'servicesAssigned')
                      }
                    />
                  </TableCell>
                  <TableCell>
                    <Switch
                      checked={userPermissions.servicesInProgress || false}
                      onChange={() =>
                        handlePermissionChange(user.idUser, 'servicesInProgress')
                      }
                    />
                  </TableCell>
                  <TableCell>
                    <Switch
                      checked={userPermissions.servicesPending || false}
                      onChange={() =>
                        handlePermissionChange(user.idUser, 'servicesPending')
                      }
                    />
                  </TableCell>
                  <TableCell>
                    <Switch
                      checked={userPermissions.servicesPendingReview || false}
                      onChange={() =>
                        handlePermissionChange(user.idUser, 'servicesPendingReview')
                      }
                    />
                  </TableCell>
                  <TableCell>
                    <Switch
                      checked={userPermissions.servicesCompleted || false}
                      onChange={() =>
                        handlePermissionChange(user.idUser, 'servicesCompleted')
                      }
                    />
                  </TableCell>
                  <TableCell>
                    <Switch
                      checked={userPermissions.editServices || false}
                      onChange={() =>
                        handlePermissionChange(user.idUser, 'editServices')
                      }
                    />
                  </TableCell>
                  <TableCell>
                    <Switch
                      checked={userPermissions.rateSegments || false}
                      onChange={() =>
                        handlePermissionChange(user.idUser, 'rateSegments')
                      }
                    />
                  </TableCell>
                  <TableCell>
                    <Switch
                      checked={userPermissions.rateEntities || false}
                      onChange={() =>
                        handlePermissionChange(user.idUser, 'rateEntities')
                      }
                    />
                  </TableCell>
                  <TableCell>
                    <Switch
                      checked={userPermissions.cookiePolicy || false}
                      onChange={() =>
                        handlePermissionChange(user.idUser, 'cookiePolicy')
                      }
                    />
                  </TableCell>
                  <TableCell>
                    <Switch
                      checked={userPermissions.legalNotice || false}
                      onChange={() =>
                        handlePermissionChange(user.idUser, 'legalNotice')
                      }
                    />
                  </TableCell>
                  <TableCell>
                    <Switch
                      checked={userPermissions.privacyPolicy || false}
                      onChange={() =>
                        handlePermissionChange(user.idUser, 'privacyPolicy')
                      }
                    />
                  </TableCell>
                  <TableCell>
                    <Switch
                      checked={userPermissions.servicesImages || false}
                      onChange={() =>
                        handlePermissionChange(user.idUser, 'servicesImages')
                      }
                    />
                  </TableCell>
                  <TableCell>
                    <Switch
                      checked={userPermissions.driverServices || false}
                      onChange={() =>
                        handlePermissionChange(user.idUser, 'driverServices')
                      }
                    />
                  </TableCell>
                  <TableCell>
                    <Switch
                      checked={userPermissions.trades || false}
                      onChange={() =>
                        handlePermissionChange(user.idUser, 'trades')
                      }
                    />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default PermissionManager;
