import api from './apiConfig';

/**
 * Obtener todos los servicios.
 * @returns {Promise<Array>} - La respuesta del servidor con la lista de todos los servicios.
 */
export const fetchAllServices = async () => {
  try {
    const response = await api.get('/services');
    return response.data;
  } catch (error) {
    console.error('Error al obtener todos los servicios:', error);
    throw error;
  }
};

/**
 * Obtener servicio por ID.
 * @param {Number|String} serviceId - ID del servicio.
 * @returns {Promise<Object>} - La respuesta del servidor con los detalles del servicio.
 */
export const fetchServiceById = async (serviceId) => {
  try {
    const response = await api.get(`/services/${serviceId}`);
    return response.data;
  } catch (error) {
    console.error(`Error al obtener el servicio con ID ${serviceId}:`, error);
    throw error;
  }
};

/**
 * Obtener servicios por ID de cliente.
 * @param {Number|String} clientId - ID del cliente.
 * @returns {Promise<Array>} - La respuesta del servidor con la lista de servicios del cliente.
 */
export const fetchServicesByClientId = async (clientId) => {
  try {
    const response = await api.get(`/services/client/${clientId}`);
    return response.data;
  } catch (error) {
    console.error(`Error al obtener los servicios para el cliente con ID ${clientId}:`, error);
    throw error;
  }
};

/**
 * Obtener servicios por estado.
 * @param {String} status - Estado del servicio.
 * @returns {Promise<Array>} - La respuesta del servidor con la lista de servicios en el estado dado.
 */
export const fetchServicesByStatus = async (status) => {
  try {
    const response = await api.get(`/services/status/${status}`);
    return response.data;
  } catch (error) {
    console.error(`Error al obtener los servicios por estado ${status}:`, error);
    throw error;
  }
};

/**
 * Obtener servicios por ID de creador.
 * @param {Number|String} userId - ID del usuario que creó el servicio.
 * @returns {Promise<Array>} - La respuesta del servidor con la lista de servicios creados por el usuario.
 */
export const fetchServicesByUserIdCreator = async (userId) => {
  try {
    const response = await api.get(`/services/user/${userId}`);
    return response.data;
  } catch (error) {
    console.error(`Error al obtener los servicios creados por el usuario con ID ${userId}:`, error);
    throw error;
  }
};

/**
 * Crear un nuevo servicio.
 * @param {Object} serviceData - Datos del servicio a crear.
 * @returns {Promise<Object>} - La respuesta del servidor con los detalles del servicio creado.
 */
export const createService = async (serviceData) => {
  try {
    const response = await api.post('/services', serviceData);
    return response.data;
  } catch (error) {
    console.error('Error al crear el servicio:', error);
    throw error;
  }
};

/**
 * Eliminar un servicio por ID.
 * @param {Number|String} serviceId - ID del servicio a eliminar.
 * @returns {Promise<void>} - La respuesta del servidor confirmando la eliminación del servicio.
 */
export const deleteServiceById = async (serviceId) => {
  try {
    await api.delete(`/services/${serviceId}`);
  } catch (error) {
    console.error(`Error al eliminar el servicio con ID ${serviceId}:`, error);
    throw error;
  }
};

/**
 * Obtener imágenes por ID de servicio.
 * @param {Number|String} serviceId - ID del servicio.
 * @returns {Promise<Array>} - La respuesta del servidor con la lista de imágenes asociadas al servicio.
 */
export const fetchImagesByServiceId = async (serviceId) => {
  try {
    const response = await api.get(`/services/${serviceId}/images`);
    return response.data;
  } catch (error) {
    console.error(`Error al obtener las imágenes para el servicio con ID ${serviceId}:`, error);
    throw error;
  }
};

/**
 * Generar PDF de recogida.
 * @param {Number|String} serviceId - ID del servicio.
 * @returns {Promise<Blob>} - La respuesta del servidor con el PDF generado.
 */
export const generatePickupPdf = async (serviceId) => {
  try {
    const response = await api.get(`/services/${serviceId}/pickup-pdf`, {
      responseType: 'blob',
    });
    return response.data;
  } catch (error) {
    console.error(`Error al generar el PDF de recogida para el servicio con ID ${serviceId}:`, error);
    throw error;
  }
};

/**
 * Generar PDF de entrega.
 * @param {Number|String} serviceId - ID del servicio.
 * @returns {Promise<Blob>} - La respuesta del servidor con el PDF generado.
 */
export const generateDeliveryPdf = async (serviceId) => {
  try {
    const response = await api.get(`/services/${serviceId}/delivery-pdf`, {
      responseType: 'blob',
    });
    return response.data;
  } catch (error) {
    console.error(`Error al generar el PDF de entrega para el servicio con ID ${serviceId}:`, error);
    throw error;
  }
};

/**
 * Obtener servicios filtrados.
 * @param {Object} filterData - Criterios de filtrado.
 * @returns {Promise<Array>} - La respuesta del servidor con la lista de servicios filtrados.
 */
export const fetchFilteredServices = async (filterData) => {
  try {
    const response = await api.post('/services/filter', filterData);
    return response.data;
  } catch (error) {
    console.error('Error al obtener los servicios filtrados:', error);
    throw error;
  }
};

/**
 * Actualizar un servicio existente.
 * @param {Number|String} serviceId - ID del servicio a actualizar.
 * @param {Object} serviceData - Datos actualizados del servicio.
 * @returns {Promise<Object>} - La respuesta del servidor con los detalles del servicio actualizado.
 */
export const updateService = async (serviceId, serviceData) => {
  try {
    const response = await api.put(`/services/${serviceId}`, serviceData);
    return response.data;
  } catch (error) {
    console.error(`Error al actualizar el servicio con ID ${serviceId}:`, error);
    throw error;
  }
};

/**
 * Obtener servicios por ID de conductor.
 * @param {Number|String} driverId - ID del conductor.
 * @returns {Promise<Array>} - La respuesta del servidor con la lista de servicios del conductor.
 */
export const fetchServicesByDriverId = async (driverId) => {
  try {
    const response = await api.get(`/services/driver/${driverId}`);
    return response.data;
  } catch (error) {
    console.error(`Error al obtener los servicios para el conductor con ID ${driverId}:`, error);
    throw error;
  }
};

/**
 * Actualizar el estado del servicio.
 * @param {Number|String} serviceId - ID del servicio.
 * @param {String} newStatus - Nuevo estado del servicio.
 * @returns {Promise<Object>} - La respuesta del servidor con los detalles del servicio actualizado.
 */
export const updateServiceStatus = async (serviceId, newStatus) => {
  try {
    const response = await api.put(`/services/${serviceId}/status`, { serviceStatus: newStatus });
    return response.data;
  } catch (error) {
    console.error(`Error al actualizar el estado del servicio con ID ${serviceId}:`, error);
    throw error;
  }
};

/**
 * Obtener servicios por ID de conductor y estado.
 * @param {Number|String} driverId - ID del conductor.
 * @param {String} status - Estado del servicio.
 * @returns {Promise<Array>} - La respuesta del servidor con la lista de servicios del conductor en el estado dado.
 */
export const fetchServicesByDriverIdAndStatus = async (driverId, status) => {
  try {
    const response = await api.get(`/services/driver/${driverId}/status/${status}`);
    return response.data;
  } catch (error) {
    console.error(`Error al obtener los servicios para el conductor con ID ${driverId} y estado ${status}:`, error);
    throw error;
  }
};
