import React, { useState } from 'react';
import {
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Collapse,
} from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import AssignmentIcon from '@mui/icons-material/Assignment';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import LogoutIcon from '@mui/icons-material/Logout';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import ScheduleIcon from '@mui/icons-material/Schedule';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import PendingIcon from '@mui/icons-material/Pending';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import EventIcon from '@mui/icons-material/Event';
import WorkIcon from '@mui/icons-material/Work';
import AddTaskIcon from '@mui/icons-material/AddTask';
import BarChartIcon from '@mui/icons-material/BarChart';
import PolicyIcon from '@mui/icons-material/Policy';
import PolicyIconOn from '@mui/icons-material/PolicyOutlined';
import PolicyIconOff from '@mui/icons-material/PolicyTwoTone';
import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary';
import '../../styles/components.scss';
import { logOut } from '../../redux/authSlice';
import SidebarWelcome from '../SidebarWelcome';

const SidebarOficinaWeDelivery = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [openServices, setOpenServices] = useState(false);
  const [openUsers, setOpenUsers] = useState(false);
  const [openPolicies, setOpenPolicies] = useState(false);
  const [openRates, setOpenRates] = useState(false);

  const handleClickServices = () => {
    setOpenServices(!openServices);
  };

  const handleClickUsers = () => {
    setOpenUsers(!openUsers);
  };

  const handleClickPolicies = () => {
    setOpenPolicies(!openPolicies);
  };

  const handleClickRates = () => {
    setOpenRates(!openRates);
  };

  const handleLogout = () => {
    dispatch(logOut());
    navigate('/');
  };

  const permissions = useSelector((state) => state.auth.permissions);

  // Agregar log para verificar los permisos
  console.log('Permissions:', permissions);

  return (
    <div className="sidebar">
      <SidebarWelcome />
      <List>
        {/* Servicios Submenu */}
        <>
          <ListItem button onClick={handleClickServices}>
            <ListItemIcon>
              <AssignmentIcon />
            </ListItemIcon>
            <ListItemText primary="Servicios" />
            {openServices ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={openServices} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {permissions?.services && (
                <ListItem button onClick={() => navigate('/oficina-wedelivery/services')} sx={{ pl: 4 }}>
                  <ListItemIcon>
                    <AssignmentIcon />
                  </ListItemIcon>
                  <ListItemText primary="Servicios" />
                </ListItem>
              )}
              {permissions?.driverServices && (
                <ListItem button onClick={() => navigate('/oficina-wedelivery/driver-services')} sx={{ pl: 4 }}>
                  <ListItemIcon><WorkIcon /></ListItemIcon>
                  <ListItemText primary="Conductores de Servicios" />
                </ListItem>
              )}
              {permissions?.trades && (
                <ListItem button onClick={() => navigate('/oficina-wedelivery/trades')} sx={{ pl: 4 }}>
                  <ListItemIcon><WorkIcon /></ListItemIcon>
                  <ListItemText primary="Vehículos de Intercambio" />
                </ListItem>
              )}
              {permissions?.createServices && (
                <ListItem button onClick={() => navigate('/oficina-wedelivery/create-services')} sx={{ pl: 4 }}>
                  <ListItemIcon>
                    <AddTaskIcon />
                  </ListItemIcon>
                  <ListItemText primary="Crear Servicios" />
                </ListItem>
              )}
              {permissions?.editServices && (
                <ListItem button onClick={() => navigate('/oficina-wedelivery/edit-services')} sx={{ pl: 4 }}>
                  <ListItemIcon><AddCircleIcon /></ListItemIcon>
                  <ListItemText primary="Editar Servicios" />
                </ListItem>
              )}
              {permissions?.servicesAssigned && (
                <ListItem button onClick={() => navigate('/oficina-wedelivery/services-assigned')} sx={{ pl: 4 }}>
                  <ListItemIcon>
                    <TaskAltIcon />
                  </ListItemIcon>
                  <ListItemText primary="Servicios Asignados" />
                </ListItem>
              )}
              {permissions?.servicesInProgress && (
                <ListItem button onClick={() => navigate('/oficina-wedelivery/services-in-progress')} sx={{ pl: 4 }}>
                  <ListItemIcon>
                    <ScheduleIcon />
                  </ListItemIcon>
                  <ListItemText primary="Servicios en Progreso" />
                </ListItem>
              )}
              {permissions?.servicesPending && (
                <ListItem button onClick={() => navigate('/oficina-wedelivery/services-pending')} sx={{ pl: 4 }}>
                  <ListItemIcon>
                    <PendingIcon />
                  </ListItemIcon>
                  <ListItemText primary="Servicios Pendientes" />
                </ListItem>
              )}
              {permissions?.servicesPendingReview && (
                <ListItem button onClick={() => navigate('/oficina-wedelivery/services-pending-review')} sx={{ pl: 4 }}>
                  <ListItemIcon>
                    <HourglassEmptyIcon />
                  </ListItemIcon>
                  <ListItemText primary="Servicios Pendientes de Revisión" />
                </ListItem>
              )}
              {permissions?.servicesCompleted && (
                <ListItem button onClick={() => navigate('/oficina-wedelivery/services-completed')} sx={{ pl: 4 }}>
                  <ListItemIcon>
                    <EventIcon />
                  </ListItemIcon>
                  <ListItemText primary="Servicios Completados" />
                </ListItem>
              )}
              {permissions?.servicesImages && (
                <ListItem button onClick={() => navigate('/oficina-wedelivery/services-images')} sx={{ pl: 4 }}>
                  <ListItemIcon><PhotoLibraryIcon /></ListItemIcon>
                  <ListItemText primary="Imágenes de Servicios" />
                </ListItem>
              )}
            </List>
          </Collapse>
        </>

        {/* Usuarios Submenu */}
        <>
          <ListItem button onClick={handleClickUsers}>
            <ListItemIcon>
              <PermContactCalendarIcon />
            </ListItemIcon>
            <ListItemText primary="Usuarios" />
            {openUsers ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={openUsers} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {permissions?.users && (
                <ListItem button onClick={() => navigate('/oficina-wedelivery/users')} sx={{ pl: 4 }}>
                  <ListItemIcon>
                    <PermContactCalendarIcon />
                  </ListItemIcon>
                  <ListItemText primary="Gestión de Usuarios" />
                </ListItem>
              )}
              {permissions?.profile && (
                <ListItem button onClick={() => navigate('/oficina-wedelivery/profile')} sx={{ pl: 4 }}>
                  <ListItemIcon>
                    <AccountCircleIcon />
                  </ListItemIcon>
                  <ListItemText primary="Perfil" />
                </ListItem>
              )}
            </List>
          </Collapse>
        </>

        {/* Tarifas Submenu */}
        <>
          <ListItem button onClick={handleClickRates}>
            <ListItemIcon>
              <AttachMoneyIcon />
            </ListItemIcon>
            <ListItemText primary="Tarifas" />
            {openRates ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={openRates} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {permissions?.rates && (
                <ListItem button onClick={() => navigate('/oficina-wedelivery/rates')} sx={{ pl: 4 }}>
                  <ListItemIcon><AttachMoneyIcon /></ListItemIcon>
                  <ListItemText primary="Gestión de Tarifas" />
                </ListItem>
              )}
              {permissions?.rateSegments && (
                <ListItem button onClick={() => navigate('/oficina-wedelivery/rate-segments')} sx={{ pl: 4 }}>
                  <ListItemIcon><BarChartIcon /></ListItemIcon>
                  <ListItemText primary="Segmentos de Tarifas" />
                </ListItem>
              )}
              {permissions?.rateEntities && (
                <ListItem button onClick={() => navigate('/oficina-wedelivery/rate-entities')} sx={{ pl: 4 }}>
                  <ListItemIcon><WorkIcon /></ListItemIcon>
                  <ListItemText primary="Entidades de Tarifas" />
                </ListItem>
              )}
            </List>
          </Collapse>
        </>

        {/* Políticas Submenu */}
        <>
          <ListItem button onClick={handleClickPolicies}>
            <ListItemIcon>
              <PolicyIcon />
            </ListItemIcon>
            <ListItemText primary="Políticas" />
            {openPolicies ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={openPolicies} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {permissions?.cookiePolicy && (
                <ListItem button onClick={() => navigate('/oficina-wedelivery/cookie-policy')} sx={{ pl: 4 }}>
                  <ListItemIcon>
                    <PolicyIcon />
                  </ListItemIcon>
                  <ListItemText primary="Política de Cookies" />
                </ListItem>
              )}
              {permissions?.legalNotice && (
                <ListItem button onClick={() => navigate('/oficina-wedelivery/legal-notice')} sx={{ pl: 4 }}>
                  <ListItemIcon>
                    <PolicyIconOn />
                  </ListItemIcon>
                  <ListItemText primary="Aviso Legal" />
                </ListItem>
              )}
              {permissions?.privacyPolicy && (
                <ListItem button onClick={() => navigate('/oficina-wedelivery/privacy-policy')} sx={{ pl: 4 }}>
                  <ListItemIcon>
                    <PolicyIconOff />
                  </ListItemIcon>
                  <ListItemText primary="Política de Privacidad" />
                </ListItem>
              )}
            </List>
          </Collapse>
        </>

        {/* Resto de items */}
        {permissions?.driverLocation && (
          <ListItem button onClick={() => navigate('/oficina-wedelivery/driver-location')}>
            <ListItemIcon><LocationOnIcon /></ListItemIcon>
            <ListItemText primary="Localización de Conductores" />
          </ListItem>
        )}

        {permissions?.reports && (
          <ListItem button onClick={() => navigate('/oficina-wedelivery/reports')}>
            <ListItemIcon><AssignmentIcon /></ListItemIcon>
            <ListItemText primary="Informes" />
          </ListItem>
        )}

        {permissions?.extraHours && (
          <ListItem button onClick={() => navigate('/oficina-wedelivery/extra-hours')}>
            <ListItemIcon><ScheduleIcon /></ListItemIcon>
            <ListItemText primary="Horas Extra" />
          </ListItem>
        )}

        {permissions?.expenses && (
          <ListItem button onClick={() => navigate('/oficina-wedelivery/expenses')}>
            <ListItemIcon><AttachMoneyIcon /></ListItemIcon>
            <ListItemText primary="Gastos" />
          </ListItem>
        )}

        <ListItem button onClick={handleLogout}>
          <ListItemIcon><LogoutIcon /></ListItemIcon>
          <ListItemText primary="Cerrar Sesión" />
        </ListItem>
      </List>
    </div>
  );
};

export default SidebarOficinaWeDelivery;
