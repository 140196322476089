import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Button,
  CircularProgress,
  TextField, // Importar TextField
} from '@mui/material';
import { fetchAllServices } from '../services/api/serviceApi';
import { createDriverService } from '../services/api/driverServiceApi';
import { fetchUsersByRoleId } from '../services/api/userApi';

const CreateDriverModal = ({ open, onClose, setDriverServices }) => {
  const [services, setServices] = useState([]);
  const [drivers, setDrivers] = useState([]);
  const [selectedServiceId, setSelectedServiceId] = useState('');
  const [selectedDriverId, setSelectedDriverId] = useState('');
  const [driverType, setDriverType] = useState('');
  const [driverRate, setDriverRate] = useState(''); // Estado para driverRate
  const [active, setActive] = useState(true); // Estado para el campo de "activo"
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const loadServices = async () => {
      try {
        const response = await fetchAllServices();
        setServices(response);
      } catch (error) {
        console.error('Error al cargar los servicios:', error);
      }
    };

    const loadDrivers = async () => {
      try {
        const response = await fetchUsersByRoleId(4);
        setDrivers(response);
      } catch (error) {
        console.error('Error al cargar los conductores:', error);
      }
    };

    loadServices();
    loadDrivers();
  }, []);

  // Este useEffect se asegura de limpiar los campos cuando se abra el modal
  useEffect(() => {
    if (open) {
      setSelectedServiceId('');
      setSelectedDriverId('');
      setDriverType('');
      setDriverRate(''); // Resetear driverRate
      setActive(true); // Restablecer a "activo"
    }
  }, [open]);

  const handleSaveDriverService = async () => {
    if (!selectedServiceId || !selectedDriverId || !driverType || driverRate === '') {
      alert("Por favor, complete todos los campos antes de guardar.");
      return;
    }

    const driverRateValue = parseFloat(driverRate);
    if (isNaN(driverRateValue)) {
      alert("La Tarifa del Conductor debe ser un número válido.");
      return;
    }

    setLoading(true);
    try {
      const newDriverService = {
        serviceId: selectedServiceId,
        driverId: selectedDriverId,
        driverType,
        driverRate: driverRateValue,
        active,
      };

      await createDriverService(newDriverService);
      onClose(); // Cierra el modal después de crear el driver service
    } catch (error) {
      console.error('Error al crear el driver service:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Añadir Nuevo Conductor al Servicio</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel id="select-service-label">Seleccionar Servicio</InputLabel>
              <Select
                labelId="select-service-label"
                value={selectedServiceId}
                onChange={(e) => setSelectedServiceId(e.target.value)}
              >
                {services.map(service => (
                  <MenuItem key={service.idService} value={service.idService}>
                    {`ID: ${service.idService} - Matrícula: ${service.vehiclePlate}`}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel id="select-driver-label">Seleccionar Conductor</InputLabel>
              <Select
                labelId="select-driver-label"
                value={selectedDriverId}
                onChange={(e) => setSelectedDriverId(e.target.value)}
              >
                {drivers.map(driver => (
                  <MenuItem key={driver.idUser} value={driver.idUser}>
                    {`ID: ${driver.idUser} - Nombre: ${driver.firstName} ${driver.lastName}`}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel id="driver-type-label">Tipo de Conductor</InputLabel>
              <Select
                labelId="driver-type-label"
                value={driverType}
                onChange={(e) => setDriverType(e.target.value)}
              >
                <MenuItem value="CONTRATO">Contrato</MenuItem>
                <MenuItem value="AUTONOMO">Autónomo</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          {/* Campo para driverRate */}
          <Grid item xs={12}>
            <TextField
              label="Tarifa del Conductor"
              type="number"
              value={driverRate}
              onChange={(e) => setDriverRate(e.target.value)}
              fullWidth
            />
          </Grid>

          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel id="active-label">Activo</InputLabel>
              <Select
                labelId="active-label"
                value={active}
                onChange={(e) => setActive(e.target.value === 'true')}
              >
                <MenuItem value={'true'}>Sí</MenuItem>
                <MenuItem value={'false'}>No</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary" disabled={loading}>
          Cancelar
        </Button>
        <Button onClick={handleSaveDriverService} color="primary" disabled={loading}>
          {loading ? <CircularProgress size={24} /> : 'Guardar Conductor'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateDriverModal;
