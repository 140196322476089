import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import { Avatar, Button, TextField, Link, Grid, Box, Typography, Container, CssBaseline, FormControlLabel, Checkbox } from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { setCredentials, loadPermissions } from '../redux/authSlice';
import { login } from '../services/api/authApi';
import '../styles/global.scss';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  const [error, setError] = useState('');
  const { token, user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    // Si ya hay un usuario autenticado, redirigir al dashboard correspondiente
    if (token && user) {
      switch (user.roleId) {
        case 1:
          navigate('/entidad');
          break;
        case 2:
          navigate('/oficina-entidad');
          break;
        case 3:
          navigate('/admin');
          break;
        case 4:
          navigate('/conductor');
          break;
        case 5:
          navigate('/oficina-wedelivery');
          break;
        default:
          navigate('/');
          break;
      }
    }
  }, [token, user, navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await login({ email, password }, rememberMe);
      dispatch(setCredentials({ token: response.token, user: response.user, rememberMe }));
  
      // Esperar a que los permisos se carguen completamente
      await dispatch(loadPermissions(response.user.idUser)).unwrap();
  
      // Redirigir al dashboard correspondiente después de cargar los permisos
      switch (response.user.roleId) {
        case 1:
          navigate('/entidad');
          break;
        case 2:
          navigate('/oficina-entidad');
          break;
        case 3:
          navigate('/admin');
          break;
        case 4:
          navigate('/conductor');
          break;
        case 5:
          navigate('/oficina-wedelivery');
          break;
        default:
          navigate('/');
          break;
      }
    } catch (err) {
      const errorMessage =
        typeof err.response?.data === 'string'
          ? err.response.data
          : err.response?.data?.message || 'Error desconocido';
      setError(errorMessage);
    }
  };
  
  
    return (
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Iniciar Sesión
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Correo Electrónico"
              name="email"
              autoComplete="email"
              autoFocus
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Contraseña"
              type="password"
              id="password"
              autoComplete="current-password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <FormControlLabel
              control={<Checkbox value={rememberMe} color="primary" onChange={(e) => setRememberMe(e.target.checked)} />}
              label="Mantener sesión iniciada"
            />
            {error && <Typography color="error" variant="body2">{error}</Typography>}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Iniciar Sesión
            </Button>
            <Grid container>
              <Grid item xs>
                <Link component={RouterLink} to="/forgot-password" variant="body2">
                  ¿Olvidaste tu contraseña?
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    );
  };
  
  export default Login;
  
